#footer {
  display: flex;
  align-items: center;
  justify-content: center;
  // justify-content: flex-start;
  min-height: 10vh;

  background: linear-gradient(
    to bottom,
    rgba(212, 64, 64, 1),
    rgba(203, 27, 27, 1),
    rgba(212, 64, 64, 1)
  );
  box-shadow: 0px 0px 2px 1px #cd9f70;
  color: white;
  // margin-top: 5em;

  p {
    font-family: "Zen Antique";
  }

  #div-toogle-theme {
    margin-left: 2.5em;
    margin-right: 25em;

    p {
      color: rgba(203, 27, 27, 1);

      &:hover {
        color: white;
        border: 1px solid white;
      }
    }
  }
}

@media (max-width: 1024px) {
  #footer {
    p {
      font-size: 1.6em;
    }
  }
}

@media (max-width: 768px) {
  #footer {
    p {
      font-size: 1.2em;
    }
  }
}

@media (max-width: 580px) {
  #footer {
    p {
      font-size: 0.9em;
    }
  }
}

@media (max-width: 425px) {
  #footer {
    p {
      font-size: 0.7em;
    }
  }
}
