#contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 110vh;
  margin: 3em 0 0 0;
  background: url("../images/antique_portal_3.jpg") no-repeat;
  background-size: 100% 100%;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 50%;
    background: rgba(0, 0, 0, 0.75);

    box-shadow: 0px 0px 2px 1px rgba(131, 158, 85, 0.77);
    border-radius: 0.15em;
    color: white;

    // padding: 3em 0 3em 0;
    padding: 2em 0 2em 0;

    p {
      display: block;
      width: 100%;
      height: 2em;
      margin: 0;
    }

    label {
      display: block;
      width: 50%;
      margin: 0.25em 0 0.25em 0;
      text-align: left;
    }

    input {
      display: block;
      margin: 0.25em 0 0.25em 0;
      padding: 0.25em 0.5em;
      width: 50%;
      box-shadow: 0px 0px 1px 2px rgba(131, 158, 85, 0.77);
      border: none;
      border-radius: 0.15em;

      text-align: left;
      font-family: "Noto Sans JP";

      &:focus {
        outline: none;
        border: none;
        box-shadow: 0px 0px 1px 3px rgba(224, 227, 161, 0.7);
      }

      &:active {
        outline: none;
        border: none;
        box-shadow: 0px 0px 1px 3px rgba(224, 227, 161, 0.7);
      }
    }

    textarea {
      margin: 0.25em 0 0 0;
      padding: 0.5em 0.5em 0.25em 0.5em;
      width: 50%;
      box-shadow: 0px 0px 1px 2px rgba(131, 158, 85, 0.77);
      border: none;
      border-radius: 0.15em;
      resize: none;

      text-align: left;
      font-family: "Noto Sans JP";

      &:focus {
        outline: none;
        border: none;
        box-shadow: 0px 0px 1px 3px rgba(224, 227, 161, 0.7);
      }

      &:active {
        outline: none;
        border: none;
        box-shadow: 0px 0px 1px 3px rgba(224, 227, 161, 0.7);
      }
    }

    button {
      width: 52%;
      height: 2.25em;
      margin-top: 1.5em;
      padding: 0.25em 0.5em;

      font-family: "Noto Sans JP";
      color: rgb(255 255 255);
      background-color: rgba(85, 158, 87, 0.88);
      // box-shadow: 0px 0px 1px 2px rgba(131, 158, 85, 0.77);
      border: 0.15em solid rgb(255 255 255 / 33%);
      border-radius: 0.15em;

      &:hover {
        border: 2px solid rgb(255 255 255 / 66%);
      }

      &:active {
        background-color: rgba(131, 158, 85, 0.77);
        border: 2px solid rgb(255 255 255 / 66%);
      }
    }
  }

  #contact-button-container button {
    width: 17.5em;
    height: 3.25em;
    margin-top: 0.5em;
    margin-bottom: 40%;

    font-family: "Noto Sans JP";
    color: rgb(255 255 255);
    background-color: rgba(85, 158, 87, 0.66);
    border: 0.15em solid rgb(255 255 255 / 33%);
    border-radius: 0.15em;

    &:hover {
      border: 2px solid rgb(255 255 255 / 66%);
    }

    &:active {
      background-color: rgba(131, 158, 85, 0.77);
      border: 2px solid rgb(255 255 255 / 66%);
    }
  }

  article {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 25%;
    background: rgba(0, 0, 0, 0.75);

    box-shadow: 0px 0px 2px 1px rgba(131, 158, 85, 0.77);
    border-radius: 0.15em;
    color: white;

    // padding: 3em 0 3em 0;
    padding: 2em 0 2em 0;
    margin-bottom: 8em;

    p {
      display: block;
      width: 100%;
      height: 2em;
      margin: 0;
    }
  }
}

@media (max-width: 1024px) {
  #contact {
    min-height: 95vh;

    article {
      width: 30%;
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
}
@media (max-width: 768px) {
  #contact {
    min-height: 87.5vh;

    article {
      width: 40%;
      margin-bottom: 7em;
    }
  }
}
@media (max-width: 580px) {
  #contact {
    min-height: 77.5vh;

    article {
      width: 55%;
      margin-bottom: 5.5em;
    }
  }
}
@media (max-width: 425px) {
  #contact {
    min-height: 72.5vh;
    // margin-top: 0;

    article {
      width: 65%;
      margin-bottom: 3.5em;
    }
  }
}
