#section-faq {
  display: flex;
  flex-direction: column;
  align-items: center;

  .h2-container {
    background: linear-gradient(
      to bottom,
      rgba(197, 175, 125, 0.97),
      rgba(188, 138, 88, 0.97),
      rgba(197, 175, 125, 0.97)
    );
    box-shadow: 0px 0px 50px 4px rgba(131, 139, 128, 0.6);
    border: solid 1px #bc8a58;
    width: 25%;
    margin: 3.5em auto 0;

    h2 {
      display: inline-block;
      color: rgb(225, 225, 225);
      margin-top: 0.5em;

      &:after {
        content: "";
        display: block;
        margin: auto;
        border: 1px solid rgb(204, 51, 51);
        width: 6em;
        bottom: -7.5%;
        left: 40%;
      }
    }
  }

  .faq-titre-white-theme {
    width: 27.5%;
    background: linear-gradient(
      to bottom,
      rgba(203, 37, 37, 1),
      rgb(175, 0, 0),
      rgba(203, 37, 37, 1)
    );

    box-shadow: 0px 0px 50px 4px rgba(131, 139, 128, 0.6);
    border: solid 3px #bc8a58;

    h2 {
      font-size: 1.75em;
      background: -webkit-linear-gradient(#e6c4a1, rgb(245, 245, 245), #e6c4a1);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      &:after {
        content: "";
        display: block;
        margin: auto;
        border: 1px solid #e6c4a1;
        width: 6em;
        bottom: -7.5%;
        left: 40%;
      }
    }
  }

  h1::selection,
  h2::selection,
  p::selection {
    background-color: rgba(15, 136, 15, 0.75);
  }

  #faq-container {
    width: 66%;
    background: linear-gradient(
        to bottom,
        rgba(237, 213, 150, 0.33) 0%,
        rgba(254, 251, 245, 0.33) 50%,
        rgba(237, 213, 150, 0.33) 100%
      ),
      url("../images/fond_beige_2.jpg") no-repeat;
    background-size: 100% 100%;
    margin-top: 5em;
    margin-bottom: 5em;
    padding-bottom: 1.5em;

    border: solid 3px #f15c4f;
    box-shadow: 0px 0px 50px 4px rgba(131, 139, 128, 0.6);

    h2 {
      color: rgb(10, 10, 10);
      margin-top: 0.5em;
      margin-bottom: 1em;
    }

    h3 {
      color: rgb(65, 65, 65);
      margin-top: 1.5em;
      margin-bottom: 1em;
      padding-left: 1.75em;
      text-align: left;
    }

    p {
      color: rgb(10, 10, 10);
      text-align: justify;
      padding: 0 2em 0 2em;
    }

    p:last-of-type {
      margin-bottom: 3.5em;
    }

    picture {
      display: block;
      margin-top: 2em;
    }

    img {
      width: 30%;
      box-shadow: 0px 0px 50px 4px rgba(131, 139, 128, 0.6);
    }

    figcaption {
      color: rgb(90, 90, 90);
      font-style: italic;
      font-size: 0.8em;
    }
  }
}

@media (max-width: 1024px) {
  #section-faq {
    .h2-container {
      margin-top: 6.5em;
    }

    .faq-titre-white-theme {
      width: 40%;
    }

    #faq-container {
      width: 97.5%;

      h3 {
        font-size: 1.6em;
      }

      p {
        font-size: 1.3em;
      }

      figcaption {
        color: rgb(80, 80, 80);
        font-size: 1.1em;
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 768px) {
  #section-faq {
    .faq-titre-white-theme {
      width: 50%;
    }

    #faq-container {
      font-size: 0.75em;

      figcaption {
        font-size: 0.9em;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 580px) {
  #section-faq {
    .faq-titre-white-theme {
      width: 75%;
    }

    #faq-container {
      figcaption {
        color: rgb(90, 90, 90);
        font-size: 0.9em;
        font-weight: 400;
      }
      img {
        width: 30%;
      }
    }
  }
}

@media (max-width: 425px) {
  #section-faq {
    .h2-container {
      margin-top: 5em;
    }
    .faq-titre-white-theme {
      width: 87.5%;
      h2 {
        font-size: 1.5em;
      }
    }

    #faq-container {
      h3 {
        font-size: 1.5em;
      }

      p {
        font-size: 1.2em;
      }
      figcaption {
        font-size: 0.75em;
      }
      img {
        width: 35%;
      }
    }
  }
}
