nav {
  width: 100%;
  position: sticky;
  top: 0;

  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 4em;
    box-sizing: border-box;

    background: linear-gradient(
      to bottom,
      rgba(203, 37, 37, 1),
      rgb(190, 0, 0),
      rgba(203, 37, 37, 1)
    );
    color: white;
    margin: 0 0 5em 0;
    box-shadow: 0px 0px 2px 1px #cd9f70;

    li {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin-right: 3.5em;
      height: 75%;
      border-bottom: 3px solid rgba(205, 159, 112, 0);
      font-family: "Zen Antique";

      a {
        color: white;
        text-decoration: none;
      }

      #faq-mobile {
        display: none;
      }
    }

    .active {
      border-bottom: 3px solid #cd9f70;
    }
  }

  .nav-white-theme {
    border-top: 3px solid #f9b46f;
    border-bottom: 3px solid #f9b46f;
    border-top: 3px solid #bc8a58;
    border-bottom: 3px solid #bc8a58;

    box-shadow: 0px 0px 2px 1px #fecfa0;
  }
}

@media (max-width: 1024px) {
  nav {
    ul {
      height: 4.5em;

      li {
        a {
          font-size: 1.5em;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  nav {
    ul {
      height: 4em;

      li {
        a {
          font-size: 1.25em;
        }

        #faq-desktop {
          display: none;
        }

        #faq-mobile {
          display: inline;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  nav {
    ul {
      height: 3em;
      margin-bottom: 1em;

      li {
        a {
          font-size: 0.8em;
        }
      }
    }
  }
}
