.App {
  text-align: center;
}

#home-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  width: 100%;
  background: linear-gradient(
      to bottom,
      rgba(146, 30, 80, 0),
      rgba(245, 111, 176, 0),
      rgba(251, 158, 205, 0.25),
      rgba(45, 237, 199, 0.3)
    ),
    url("../images/lever de soleil.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  img {
    display: block;
    width: 100%;
  }

  #home-header-title-container {
    h1,
    h2 {
      font-family: "Zen Antique";
      padding-bottom: 0.1em;
    }

    h1 {
      background: -webkit-linear-gradient(#333, rgb(185, 16, 16), rgb(131 0 0));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      font-family: "Noto Sans JP";
      background: #333;

      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h1,
    p {
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 0.1em;
      line-height: 1.25em;
    }
  }

  #home-header-button-container {
    a {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 17.5em;
      height: 3.25em;
      margin-bottom: 25%;

      font-family: "Noto Sans JP";
      font-size: 0.55em;
      color: rgb(255 255 255);
      background-color: rgba(46, 147, 160, 0.66);
      border: 0.15em solid rgb(255 255 255 / 33%);
      border-radius: 0.15em;

      text-decoration: none;

      &:hover {
        border: 2px solid rgb(255 255 255 / 66%);
      }

      &:active {
        background-color: rgba(41, 139, 152, 0.77);
        border: 2px solid rgb(255 255 255 / 66%);
      }
    }
  }
}

@media (max-width: 1024px) {
  #home-header {
    background: linear-gradient(
        to bottom,
        rgba(146, 30, 80, 0),
        rgba(245, 111, 176, 0),
        rgba(251, 158, 205, 0.35),
        rgba(45, 237, 199, 0.4)
      ),
      url("../images/lever de soleil.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    img {
      display: block;
      width: 100%;
    }

    #home-header-title-container {
      h1 {
        margin-top: 2em;
        font-size: 1.7em;
      }
      p {
        font-weight: 600;
      }
    }

    #home-header-button-container {
      a {
        width: 17.5em;
        height: 3.25em;
        margin-bottom: 63%;
      }
    }
  }
}

@media (max-width: 768px) {
  #home-header {
    #home-header-title-container {
      h1 {
        margin-top: 3em;
        font-size: 1.3em;
      }
      p {
        font-size: 0.78em;
      }
    }

    #home-header-button-container {
      a {
        margin-bottom: 55%;
      }
    }
  }
}

@media (max-width: 580px) {
  #home-header {
    #home-header-title-container {
      h1 {
        margin-top: 4em;
        font-size: 1.3em;
      }
    }

    #home-header-button-container {
      a {
        margin-bottom: 72%;
      }
    }
  }
}

@media (max-width: 425px) {
  #home-header {
    #home-header-title-container {
      h1 {
        margin-top: 4.5em;
        font-size: 1.2em;
      }
      p {
        font-size: 0.7em;
      }
    }

    #home-header-button-container {
      a {
        margin-bottom: 62%;
      }
    }
  }
}
