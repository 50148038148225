#main-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 40vh;
  width: 100%;
  color: rgb(204, 51, 51);

  #logo {
    // width: 8%;
    width: 5%;
    margin: 1em auto 0;
  }

  #logo img {
    width: 100%;
    height: 100%;
  }

  #title {
    display: inline-block;
    font-family: "Zen Antique";
    // font-size: 1.75em;
    font-size: 1.8em;
    margin: 0 0 2em 0;

    background: -webkit-linear-gradient(#333, rgb(204, 51, 51), #cd9f70);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0.1em;
    font-weight: bold;
  }

  #title.header-white-theme {
    background: -webkit-linear-gradient(#333, rgb(204, 51, 51), #a67a4e);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    // &:after{
    //     content:"";
    //     display: block;
    //     margin: auto;
    //     border: 1px solid #e6c4a1;
    //     width: 15em;
    //     bottom: -7.5%;
    //     left: 40%;
    // }
  }
}

@media (max-width: 1024px) {
  #main-header {
    min-height: 20vh;

    #logo {
      width: 7.5%;
    }

    #title {
      font-size: 2em;
    }
  }
}

@media (max-width: 768px) {
  #main-header {
    #title {
      font-size: 1.6em;
    }
  }
}

@media (max-width: 580px) {
  #main-header {
    #title {
      font-size: 1.1em;
    }
  }
}

@media (max-width: 425px) {
  #main-header {
    #title {
      font-size: 0.9em;
    }
  }
}
