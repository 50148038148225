#main {
  width: 100%;
  margin: 0 auto;

  text-align: center;
  background: linear-gradient(
      to right,
      rgba(20, 20, 20, 0.9) 50%,
      rgba(225, 225, 225, 0.9) 50%
    ),
    url("../images/marbre_1.jpg") no-repeat;
  // background: linear-gradient(to right, rgba(20,20,20, 0.9)0 50%, rgba(225, 225, 225, 0.9)50% 100%), url('../images/marbre_1.jpg') no-repeat;
  // background: linear-gradient(to right, rgba(255, 255, 240, 0.9)50%, rgba(255, 255, 240, 0.9)50%), url('../images/marbre_2.jpg') no-repeat;
  background-size: 100% 100%;

  h1::selection,
  h2::selection,
  h3::selection,
  h4::selection,
  p::selection,
  span::selection,
  img::selection,
  a::selection {
    background-color: rgba(15, 136, 15, 0.6);
  }
}

#main.white-theme {
  // background: linear-gradient(to right, rgba(255, 255, 240, 0.9)50%, rgba(255, 255, 240, 0.9)50%), url('../images/marbre_2.jpg') no-repeat;
  // background: linear-gradient(to right, rgba(253, 253, 225, 0.9)33%, rgba(255, 255, 240, 0.9)66%, rgba(253, 253, 225, 0.9)100%), url('../images/marbre_2.jpg') no-repeat;
  // background: linear-gradient(to right, rgba(251, 251, 210, 0.9)20%, rgba(253, 253, 225, 0.9)40%, rgba(255, 255, 240, 0.9)60%, rgba(253, 253, 225, 0.9)80%, rgba(251, 251, 210, 0.9)100%), url('../images/marbre_2.jpg') no-repeat;
  // background: linear-gradient(to right, rgba(239, 239, 216, 0.9)33%, rgba(235, 235, 221, 0.9)66%, rgba(239, 239, 216, 0.9)100%), url('../images/marbre_2.jpg') no-repeat;
  background: linear-gradient(
      to right,
      rgba(239, 239, 216, 0.9) 33%,
      rgba(235, 235, 221, 0.9) 66%,
      rgba(239, 239, 216, 0.9) 100%
    ),
    url("../images/marbre_2.jpg") no-repeat;
  // background: linear-gradient(to right, rgba(211, 211, 190, 0.9)33%, rgba(218, 218, 204, 0.9)66%, rgba(211, 211, 190, 0.9)100%), url('../images/marbre_2.jpg') no-repeat;
  background-size: 100% 100%;
}
